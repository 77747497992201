@media (max-width: 1200px) {
    .bottom_text {
        font-size: 150px;
        line-height: 200px;
        margin-bottom: 35px;
        margin-top: 0px;
    }
    .main_button {
        font-size: 16px;
    }
    .top_text {
        font-size: 40px;
    }
}
@media (max-width: 1050px) {
    footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .footer_navigation {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: unset;
    }
}
@media (max-width: 850px) {
    .bottom_text {
        font-size: 110px;
        line-height: 135px;
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .top_text {
        font-size: 30px;
        line-height: 30px;
        margin: 0;
        width: 100%;
        margin-left: 15px;
    }
}
@media (max-width: 600px) {
    .button_wrapper {
        flex-direction: column;
        gap: 20px;
    }
    .main_button {
        font-size: 20px;
    }
    .bottom_text {
        font-size: 90px;
        line-height: 125px;
        margin-bottom: 25px;
        margin-top: 0px;
    }
    .privacy_title {
        font-size: 28px;
        line-height: 30px;
    }
}
@media (max-width: 600px) {
    .footer_link {
        font-size: 12px;
    }
    .footer_text {
        font-size: 12px;
    }
    .bottom_text {
        font-size: 65px;
        line-height: 90px;
        margin-bottom: 25px;
        margin-top: 0px;
    }
    .top_text {
        font-size: 25px;
        line-height: 30px;
        margin: 0;
        width: 100%;
        margin-left: 15px;
    }
    .main_button {
        font-size: 16px;
        height: 40px;
    }
}
@media (max-width: 600px) {
    .footer_navigation {
        flex-direction: column;
        align-items: center;
        gap: 3px;
    }
}
