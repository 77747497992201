@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}
#root {
    height: 100%;
    width: 100%;
}
* {
    box-sizing: border-box;
    max-width: none;
}
body {
    background-color: #ffffff;
    color: #ffffff;
    font-family: "Jost", sans-serif;
}
a {
    color: inherit;
    font-family: inherit;
    text-decoration: unset;
}
a:hover {
    text-decoration: underline;
}

.big_title {
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    margin: 0;
    color: #fff;
}
.normal_text {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    color: #fff;
}
.small_text {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: #fff;
}
.medium_title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin: 0;
    color: #fff;
}
.sub_text {
    text-align: center;
    opacity: 0.6;
}
.linebreak {
    white-space: pre-line;
}
.padding {
    padding-left: 20px;
    padding-right: 20px;
}
.error_text {
    color: red;
}
.title_nowrap {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.main_wrapper {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer_text {
    opacity: 1;
    font-weight: 200;
}
footer {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    display: flex;
}
.footer_navigation {
    display: flex;
    gap: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main_button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    appearance: none;
    display: flex;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 30px;
    width: 300px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    transition: background-color 0.3s;
    width: 100%;
    text-decoration: unset;
}
.main_button:hover {
    background-color: rgba(5, 7, 29, 0.4);
    text-decoration: unset;
}
.main_button.inactive {
    pointer-events: none;
    opacity: 0.5;
}
.bottom_text {
    margin: 0;
    font-size: 200px;
    line-height: 200px;
    margin-bottom: 70px;
    margin-top: 35px;
    font-weight: 600;
    animation: appear 4s ease;
    animation-delay: 3000ms;
    opacity: 0;
    pointer-events: none;
}

.top_text {
    font-size: 50px;
    line-height: 30px;
    margin: 0;
    width: 100%;
    margin-left: 30px;
    animation: appear 4s ease;
    animation-delay: 1000ms;
    opacity: 0;
    pointer-events: none;
}

.button_wrapper {
    display: flex;
    gap: 40px;
    width: 100%;
    justify-content: space-between;
    opacity: 0;
    animation: appear 4s ease;
    animation-delay: 5000ms;
}

.button_wrapper.single {
    margin-top: 20px;
}
.no_animation {
    animation: unset;
    animation-delay: unset;
}
.background {
    background: linear-gradient(
        -45deg,
        #05071d 0%,
        #c449b6 25%,
        #3313f4 50%,
        #45aff3 75%,
        #c449b6 99%
    );
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    position: fixed;
    opacity: 1;
    z-index: -2;
}
.active {
    opacity: 1;
}
.privacy_title {
    margin-top: 0;
}
@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.admin_body {
    height: 100%;
    width: 100%;
}
.login_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    padding-top: 20px;
    height: 100%;
    width: 100%;
}
.admin_body {
    color: #000;
}
.admin_login_wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 300px;
}
.admin_input_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
}
input {
    appearance: none;
    outline: none;
    border: none;
    background-color: unset;
}
textarea {
    outline: none;
    border: none;
    background-color: unset;
    resize: none;
    max-height: 600px;
    max-width: 500px;
    height: 200px;
}
.admin_input {
    border: 1px solid #05071d;
    border-radius: 8px;
    padding: 10px;
    max-width: 400px;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    font-family: "Jost";
    max-width: 500px;
}
[type="checkbox"].admin_input {
    appearance: auto;
    height: 13px;
    width: 13px;
}
.admin_select {
    padding: 10px;
    border: 1px solid #05071d;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    font-family: "Jost";
}
.admin_login_text {
    font-size: 18px;
    color: #fff;
}

.admin_login_button {
    appearance: none;
    background: unset;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 42px;
    border: 1px solid #05071d;
    background-color: #05071d;
    color: #fff;
    font-family: "Jost";
    font-size: 24px;
    line-height: 26px;
    cursor: pointer;
    transition: 0.2s;
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    max-height: 42px;
}
.admin_login_button:hover {
    background-color: #0a0e38;
    text-decoration: unset;
}
.admin_login_button.red {
    background-color: #7a0400;
}
.admin_login_button.red:hover {
    background-color: #490200;
}
.admin_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
}
.sidebar_content {
    width: 75px;
    position: relative;
}
.sidebar_wrapper {
    width: 75px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #45aff3;
    border-right: 1px solid #05071d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    justify-content: space-between;
    gap: 30px;
}
.sidebar_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.sidebar_button_wrapper.active {
    border-bottom: 1px solid white;
}
.sidebar_button_wrapper.withPadding {
    padding-bottom: 8px;
}
.sidebar_button_wrapper {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.sidebar_button_wrapper .tooltiptext {
    visibility: hidden;
    position: absolute;
    background-color: #05071d;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
    left: 125%;
}

.sidebar_button_wrapper .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #05071d transparent transparent;
}

.sidebar_button_wrapper:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.users_section {
    flex: 1;
    padding: 20px;
}

.users_filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 700px;
    gap: 10px;
}

.bulk_update {
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
}
.packages_list_col {
    display: flex;
    flex-direction: column;
    flex:1;
}
.packages_list_wrapper {
    display: flex;
    flex-direction: row;
    max-width: 700px;
    align-items: flex-start;
    padding-bottom: 200px;
    gap: 10px;
}
.users_list_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    align-items: center;
    padding-bottom: 200px;
}
.users_list_wrapper.diary {
    max-width: unset;
}
.users_show_more {
    color: #05071d;
    cursor: pointer;
    font-size: 18px;
    width: min-content;
    white-space: nowrap;
    margin-top: 20px;
}
.users_show_more:hover {
    text-decoration: underline;
}
.users_list_user_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    width: 100%;
}
.users_list_left {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
}
.users_list_right {
    display: flex;
    align-items: center;
    gap: 10px;
}
.users_list_user_button {
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination_wrapper {
    margin-top:20px;
}

.user_wrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.user_line_wrapper {
    display: grid;
    grid-template-columns: auto 1fr; /* First column auto-sized, second column takes remaining space */
    column-gap: 50px; /* Adjust the gap between columns */
    row-gap: 20px;
}

.user_title {
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
}

.user_value {
    max-width: 350px; /* Set the maximum width for the value column */
    display: flex;
    flex-direction: column; /* Allow multiple lines */
    align-items: flex-start; /* Align text at the start of each line */
    justify-content: center;
}
.user_row {
    display: grid;
    grid-template-columns: auto 1fr; /* First column auto-sized, second column takes remaining space */
    grid-gap: 50px; /* Adjust the gap between columns */
}

.content_navigation_wrapper {
    display: flex;
    gap: 10px;
}

.content_navigation_link {
    text-decoration-color: #45aff3;
    font-size: 20px;
}
.content_navigation_link.active {
    text-decoration-line: underline;
}
.content_navigation_link:hover {
    text-decoration-color: #45aff3;
    text-decoration-line: underline;
}

.user_avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.user_avatar.meditation {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    border-radius: 20px;
}
.user_avatar.video {
    width: 200px;
    aspect-ratio: 16/9;
    min-width: 200px;
    border-radius: 10px;
}
.user_avatar_wrapper.meditation {
    width: min-content;
}
.user_avatar_img {
    width: 100%;
}
.user_avatar_wrapper {
    margin-bottom: 20px;
}
.meditation_section {
    margin-top: 20px;
}
.meditation_search_wrapper {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
}
.admin_login_button.meditation {
    max-width: 130px;
}
.meditation_file_input {
    display: none;
}
.meditation_file_label {
    width: 200px;
}
.admin_small_text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
}
.meditation_track_wrapper {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.file_text {
    margin-top: 5px;
}
.popup_background {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
}
.popup_wrapper {
    display: flex;
    justify-content: center;
    align-items: cetner;
    gap: 20px;
    height: 150px;
    width: 250px;
    background-color: white;
    border-radius: 20px;
    flex-direction: column;
    padding: 30px;
}
.popup_button_wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
}
.popup_button_wrapper button {
    margin-top: 0;
}
.separator {
    height: 20px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Login */

.login_link_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

/* App styles */

.app_background {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #05071d;
    background-image: url("../img/background.png");
    background-size: 100% 100%;
    z-index: -1;
}
.panel_layout {
    position: relative;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
}
.panel_layout_tabs {
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #05071d;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.panel_layout_tab {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
}
.panel_layout_tab.active {
    opacity: 1;
}
.panel_main_section,
.panel_settings_section,
.panel_meditations_section,
.panel_list_section,
.panel_track_section {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 0;
    gap: 20px;
    flex: 1;
}
.daily_buttons_section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.daily_buttons_wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    overflow-y: hidden;
}
.daily_buttons_wrapper::-webkit-scrollbar {
    display: none;
}
.daily_button_wrapper {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
}
.daily_button_circle {
    display: flex;
    height: 70px;
    width: 70px;
    border: solid 5px #402e5f;
    background-color: "rgba(255, 255, 255, .1)";
    border-radius: 35px;
    justify-content: center;
    align-items: center;
}
.daily_button_link {
    display: flex;
    text-decoration: none !important;
}
.daily_button_link.disabled {
    pointer-events: none;
}
.daily_button_text {
    text-align: center;
}
.video_wrapper {
    display: flex;
    flex-direction: column;
}
.video_image {
    width: 100%;
}
.video_image_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    width: 160px;
    height: 90px;
    border-radius: 10px;
}
.video_title {
    font-weight: 700;
    margin-top: 6px;
    line-height: 20px;
    height: 20px;
    max-width: 160px;
    text-overflow: ellipsis;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
}
.video_button {
    opacity: 0.5;
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
}
.video_button_text {
    line-height: 18px;
}

/*Settings*/

.panel_settings_section {
    padding-top: 40px;
}

.settings_user_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
}
.settings_avatar_wrapper {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    padding: 2px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.settings_avatar {
    width: 100%;
    border-radius: 50%;
}
.settings_username {
    text-overflow: ellipsis;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
}
.button_list_wrapper {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.faq_line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.faq_text {
    font-size: 16px;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Meditations page */

.panel_meditations_section {
    padding-top: 40px;
    align-items: center;
    height: 100%;
}
.meditations_text_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}
.meditations_text_wrapper h1 {
    text-align: center;
}
.meditations_list_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    height: 100%;
    flex: 1;
}
.meditation_wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    align-items: center;
}
.meditation_right {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
    padding: 25px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    align-items: center;
    overflow: hidden;
}
.meditation_title {
    font-weight: 400;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}
.meditation_button_wrapper {
    background: rgb(130, 69, 198);
    background: linear-gradient(
        135deg,
        rgba(130, 69, 198, 1) 0%,
        rgba(75, 37, 163, 1) 70%
    );
    height: 50px;
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}
.meditation_wrapper.disabled {
    pointer-events: none;
}
.meditation_wrapper.disabled .meditation_title,
.meditation_wrapper.disabled .meditation_button_wrapper {
    opacity: 0.5;
}
.meditation_icon_wrapper {
    opacity: 0.7;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* List page */

.panel_list_section {
    align-items: center;
    height: 100%;
}
.list_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
}
.list_small_text_wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.list_clear {
    cursor: pointer;
}
.list_clear:hover {
    text-decoration: underline;
}
.list_input_wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.list_scroll {
    max-height: calc(100vh - 300px);
    min-height: calc(100vh - 300px);
    flex: 1;
}
.list_scroll.check {
    max-height: calc(100vh - 290px);
    min-height: calc(100vh - 290px);
    margin-top:10px;
}
.list_list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}
.list_entry_wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    min-height: 30px;
}
.list_entry_left {
    display:flex;
    gap:10px;
    align-items: center;
    width:100%;
    max-width:100%;
}
.list_entry_text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}
.list_cross_wrapper {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.list_button {
    width: calc(100% - 40px);
    max-width: 860px;
    position: fixed;
    bottom: 70px;
}

/* Modal */

.modal_background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal_wrapper {
    background-color: rgba(5, 7, 29, 0.4);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
}
.modal_button {
    appearance: none;
    outline: unset;
    border: unset;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    transition: 0.2s;
}
.modal_button:hover,
.modal_line:hover {
    background-color: rgba(255, 255, 255, 0.4);
}
.modal_line_text {
    opacity: 0.5;
}
.modal_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    transition: 0.2s;
    text-decoration: unset !important;
}
.modal_line_left,
.modal_line_right {
    display: flex;
    gap: 10px;
    align-items: center;
}
.modal_lines_wrapper {
    margin: 10px 0;
    gap: 10px;
    display: flex;
    flex-direction: column;
}
.modal_line_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
}
.modal_line_arrow_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px;
    width: 10px;
}

/* Support page */

.support_wrapper .admin_input {
    width: 100%;
    max-width: unset;
}
.support_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
}
.support_button {
    width: 100%;
    max-width: unset;
}

/* Track page */

.panel_track_section {
    padding-top: 60px;
    align-items: center;
    height: 100%;
}
.panel_track_section .meditations_list_wrapper {
    gap:10px;
}
.track_cover_wrapper {
    display:flex;
    justify-content: center;
    align-items: center;
    max-width:600px;
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 20px;
}
.track_cover {
    width:100%;
    object-fit: cover;
    aspect-ratio: 1/1;
}
.track_top_wrapper {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    flex:1;
}
.track_description_wrapper {
    padding: 20px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, .1);
    max-width: 510px;
    width:85%;
}
.track_description_wrapper span {
    white-space: pre-line;
}
.track_bottom_wrapper {
    display:flex;
    flex-direction: column;
    gap:10px;
    align-items: center;
}
.track_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
}
.track_slider_wrapper {
    width:100%;
    display:flex;
    flex-direction: column;
    margin-top:-16px;
}
.track_time_text {
    font-size: 10px;
    line-height: 14px;
}
.track_time_wrapper {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top:-10px;
}
.track_controls_wrapper {
    display:flex;
    gap:20px;
    justify-content: center;
    margin-top:-10px;
}
.track_audio {
    display:none;
}